import * as icons from '@/assets/icons';
import * as specificIcons from '@/assets/icons/specific';

/**
 * @typedef {Object} DeviceType
 */
const DeviceType = Object.freeze({
  FOURWAY: '4way',
  TWOWAYEXIT: '2way',
  STAIRWAY: 'stairs',
  OUTDOOR: 'outdoor',
  SUPPLEMENTAL: 'supplemental',
  NORTH: 'north',
  LOCATION: 'location'
});

/**
 * Returns the device types for the real devices
 */
const RealDeviceTypes = [
  DeviceType.FOURWAY,
  DeviceType.TWOWAYEXIT,
  DeviceType.STAIRWAY,
  DeviceType.OUTDOOR,
  DeviceType.SUPPLEMENTAL
];

/**
 * @typedef {Object} DeviceComponent
 */
const DeviceComponent = Object.freeze({
  ARROW: 'arrow',
  TOP: 'arrowTop',
  RIGHT: 'arrowRight',
  BOTTOM: 'arrowBottom',
  LEFT: 'arrowLeft',
  TEXT: 'text',
  AUDIO: 'audio',
  STROBES: 'strobes',
  STROBESTOP: 'strobesTop',
  STROBESRIGHT: 'strobesRight',
  STROBESBOTTOM: 'strobesBottom',
  STROBESLEFT: 'strobesLeft'
});

/**
 * Returns the arrow component's name or the default component's name
 * @param {DeviceComponent} component
 */
const formatDeviceArrowComponent = (component) => {
  return component?.includes(DeviceComponent.ARROW)
    ? component?.slice(DeviceComponent.ARROW.length)
    : component;
};

/**
 * Returns the strobes component's name or the default component's name
 * @param {DeviceComponent} component
 */
const formatDeviceStrobesComponent = (component) => {
  return component.includes(DeviceComponent.STROBES)
    ? component.slice(DeviceComponent.STROBES.length) + ' Strobes'
    : component;
};

/**
 * Returns the title of the device type
 * @param {DeviceType} deviceType
 * @returns {string}
 */
const getDeviceTitle = (deviceType) => {
  switch (deviceType) {
    case DeviceType.FOURWAY:
      return '4-Way Sign';
    case DeviceType.TWOWAYEXIT:
      return '2-Way Exit Sign';
    case DeviceType.STAIRWAY:
      return 'Stairway Sign';
    case DeviceType.OUTDOOR:
      return 'Outdoor Sign';
    case DeviceType.SUPPLEMENTAL:
      return 'Supplemental Sign';
    case DeviceType.NORTH:
      return 'North Sign';
    case DeviceType.LOCATION:
      return 'Location Sign';
    default:
      return '';
  }
};

/**
 * Returns the asset of the device type
 * @param {DeviceType} deviceType
 * @returns
 */
const getDeviceSign = (deviceType) => {
  switch (deviceType) {
    case DeviceType.FOURWAY:
      return icons.FourWaySign;
    case DeviceType.TWOWAYEXIT:
      return icons.TwoWayExitSign;
    case DeviceType.STAIRWAY:
      return icons.StairwaySign;
    case DeviceType.OUTDOOR:
      return icons.OutdoorSign;
    case DeviceType.SUPPLEMENTAL:
      return icons.SupplementalSign;
    case DeviceType.NORTH:
      return icons.NorthSign;
    case DeviceType.LOCATION:
      return icons.LocationSign;
    default:
      return null;
  }
};

/**
 * Returns the asset of the specific device type
 * @param {DeviceType} deviceType
 * @param {DeviceFeature} deviceFeature
 * @returns
 */
const getSpecificDeviceSign = (deviceType, deviceFeature) => {
  switch (`${deviceType}-${deviceFeature}`) {
    case `${DeviceType.FOURWAY}-${DeviceComponent.TOP}`:
      return specificIcons.FourWayTopSign;
    case `${DeviceType.FOURWAY}-${DeviceComponent.RIGHT}`:
      return specificIcons.FourWayRightSign;
    case `${DeviceType.FOURWAY}-${DeviceComponent.BOTTOM}`:
      return specificIcons.FourWayBottomSign;
    case `${DeviceType.FOURWAY}-${DeviceComponent.LEFT}`:
      return specificIcons.FourWayLeftSign;
    case `${DeviceType.TWOWAYEXIT}-${DeviceComponent.RIGHT}`:
      return specificIcons.RightExitSign;
    case `${DeviceType.TWOWAYEXIT}-${DeviceComponent.LEFT}`:
      return specificIcons.LeftExitSign;
    case `${DeviceType.TWOWAYEXIT}-${DeviceComponent.TEXT}`:
      return specificIcons.TextExitSign;
    case `${DeviceType.STAIRWAY}-${DeviceComponent.TOP}`:
      return specificIcons.StairwayTopSign;
    case `${DeviceType.STAIRWAY}-${DeviceComponent.BOTTOM}`:
      return specificIcons.StairwayBottomSign;
    case `${DeviceType.OUTDOOR}-${DeviceComponent.RIGHT}`:
      return specificIcons.OutdoorRightSign;
    case `${DeviceType.OUTDOOR}-${DeviceComponent.LEFT}`:
      return specificIcons.OutdoorLeftSign;
    case `${DeviceType.SUPPLEMENTAL}-${DeviceComponent.RIGHT}`:
      return specificIcons.SupplementalRightSign;
    case `${DeviceType.SUPPLEMENTAL}-${DeviceComponent.LEFT}`:
      return specificIcons.SupplementalLeftSign;
    default:
      return null;
  }
};

/**
 * Returns the arrow components of the device type
 * @param {DeviceType} deviceType
 */
const getDeviceArrowComponents = (deviceType) => {
  switch (deviceType) {
    case DeviceType.FOURWAY:
      return [
        DeviceComponent.TOP,
        DeviceComponent.RIGHT,
        DeviceComponent.BOTTOM,
        DeviceComponent.LEFT
      ];
    case DeviceType.TWOWAYEXIT:
      return [DeviceComponent.RIGHT, DeviceComponent.LEFT];
    case DeviceType.STAIRWAY:
      return [DeviceComponent.TOP, DeviceComponent.BOTTOM];
    case DeviceType.OUTDOOR:
      return [DeviceComponent.RIGHT, DeviceComponent.LEFT];
    case DeviceType.SUPPLEMENTAL:
      return [DeviceComponent.RIGHT, DeviceComponent.LEFT];
    default:
      return null;
  }
};

/**
 * Returns the non arrow components of the device type
 * @param {DeviceType} deviceType
 */
const getExtraDeviceComponents = (deviceType) => {
  switch (deviceType) {
    case DeviceType.FOURWAY:
      return [
        DeviceComponent.AUDIO,
        DeviceComponent.STROBESTOP,
        DeviceComponent.STROBESRIGHT,
        DeviceComponent.STROBESBOTTOM,
        DeviceComponent.STROBESLEFT
      ];
    case DeviceType.STAIRWAY:
      return [DeviceComponent.AUDIO, DeviceComponent.STROBESTOP, DeviceComponent.STROBESBOTTOM];
    case DeviceType.TWOWAYEXIT:
      return [DeviceComponent.TEXT, DeviceComponent.AUDIO, DeviceComponent.STROBES];
    default:
      return [DeviceComponent.AUDIO, DeviceComponent.STROBES];
  }
};

/**
 * Checks if the device type is either a North or Location sign
 * @param {DeviceType} deviceType
 */
const isToolSign = (deviceType) => [DeviceType.NORTH, DeviceType.LOCATION].includes(deviceType);

/**
 * Checks if a device of a specific type exists in the provided list of devices.
 * @param {Array} virtualDevices - An array of sign locations objects.
 * @param {DeviceType} type - The type of the device to search for.
 * @returns {boolean} - Returns true if a device of the specified type exists, otherwise false.
 */
const deviceTypeExists = (virtualDevices, type) => virtualDevices?.some((vd) => vd.type === type);

export {
  DeviceType,
  RealDeviceTypes,
  DeviceComponent,
  getDeviceSign,
  getSpecificDeviceSign,
  deviceTypeExists,
  getDeviceTitle,
  getDeviceArrowComponents,
  getExtraDeviceComponents,
  formatDeviceArrowComponent,
  formatDeviceStrobesComponent,
  isToolSign
};
