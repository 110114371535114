import { defineStore } from 'pinia';
import { gql } from '@apollo/client/core';
import { apolloClient } from '@/config/apolloClient';
import { getArrayLastItem } from '@/utils/functions';

export const useAlerts = defineStore('alertsStore', {
  state: () => ({
    alerts: [],
    priorities: {
      evacuate: { title: 'Evacuate', order: 1 },
      high: { title: 'High', order: 2 },
      medium: { title: 'Medium', order: 3 },
      low: { title: 'Low', order: 4 }
    }
  }),
  actions: {
    initSubscriptions() {
      apolloClient
        .subscribe({
          query: gql`
            subscription {
              alertUpdates {
                alert
                type
              }
            }
          `
        })
        .subscribe({
          next: (data) => {
            const alertUpdates = data.data.alertUpdates;

            if (alertUpdates) {
              if (alertUpdates.type == 'created') {
                this.alerts.push(alertUpdates.alert);
              }
              if (alertUpdates.type == 'updated') {
                const alert = alertUpdates.alert;
                const index = this.alerts.findIndex((e) => e.entityId == alert.entityId);
                if (index != -1) {
                  this.alerts[index] = alert;
                }
              }
            }
          }
        });
    },
    async fetchAlerts() {
      this.alerts = [];

      const res = await apolloClient.query({
        query: gql`
          query ($statuses: [String!]) {
            alerts(statuses: $statuses) {
              entityId
              deviceEntityId
              priority
              statusHistory {
                status
                timestamp
                userId
                message
              }
              createdOn
              updatedOn
              type
              typeData {
                ... on HardwareAlertTypeData {
                  message
                }
                ... on MLAlertTypeData {
                  currentStatus
                  mlData
                }
              }
            }
          }
        `,
        variables: {
          statuses: ['active', 'evacuation', 'resolved']
        }
      });

      if (res) {
        // Add the highest confidence algorithm to each alert.
        res.data.alerts.forEach((e) => {
          if (e.type !== 'hardware') {
            const newAlert = JSON.parse(JSON.stringify(e));
            if (newAlert.typeData?.algorithms) {
              newAlert.highestConfidence = this._getHighestConfidence(e);
              newAlert.typeData.algorithms.sort((a, b) => b.confidence - a.confidence);
            }
            if (newAlert.statusHistory) {
              newAlert.status = getArrayLastItem(newAlert.statusHistory)?.status;
            }
            newAlert.priorityOrder = this.priorities[newAlert.priority]?.order;
            this.alerts.push(newAlert);
          } else {
            this.alerts.push(e);
          }
        });
      }
    },
    getHardwareAlertsOfDevice(deviceEntityId, statuses = ['active']) {
      return this.alerts.filter(
        (e) =>
          e.deviceEntityId == deviceEntityId &&
          e.type == 'hardware' &&
          statuses.includes(e.statusHistory[e.statusHistory.length - 1].status)
      );
    },
    _getHighestConfidence(alert) {
      return alert.typeData.algorithms.reduce((prev, current) =>
        prev.confidence > current.confidence ? prev : current
      );
    }
  }
});
