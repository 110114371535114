<script setup>
import { RouterView } from 'vue-router';
import AlertsStack from '@/components/AlertsStack.vue';
import { useAlerts } from '@/stores/useAlerts';
import { onMounted } from 'vue';

const alertsStore = useAlerts();

onMounted(async () => {
  await alertsStore.fetchAlerts();
  alertsStore.initSubscriptions();
});
</script>

<template>
  <RouterView />
  <AlertsStack />
  <!-- Load assets globally -->
  <SvgAssets />
  <RightBarSvgAssets />
</template>
