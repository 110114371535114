const strings = {
  errors: {
    missingProperty: (property) => `missing property: ${property}.`,
    cannotOverrideProperty: (property) => `cannot override property: ${property}.`,
    nullOrUndefined: (property) => `${property} is null or undefined.`,
    valueNotValid: (property, value) => `${property} is not valid: ${value}.`,
    notFoundInArray: (value, arrayName) => `${value} not found in array: ${arrayName}.`
  }
};

export default strings;

export const DEFAULT_EVACUATION_PLAN = 'default';
export const EXIT_SIGN = 'exitSign';

/**
 * Colors that match the hardware lights
 */
export const ARROW_COLORS = Object.freeze({
  OFF: '#00000000',
  GREEN: '#00FF0000',
  RED: '#FF000000',
  BLUE: '#0000FF00',
  YELLOW: '#FFFF0000',
  WHITE: '#000000FF'
});

/**
 * Keys for all the different sidebar sections
 */
export const SIDEBAR_KEYS = Object.freeze({
  LIVE_VIEW: 'liveView',
  CONFIGURE: 'configure',
  EVAC: 'evac',
  OPERATOR: 'operator',
  DETAILS: 'details',
  SETTINGS: 'settings'
});

export const ROLES = Object.freeze({
  'GLOBAL ADMIN': 'GLOBAL ADMIN',
  USER: 'USER',
  'ORG ADMIN': 'ORG ADMIN',
  'FACILITY ADMIN': 'FACILITY ADMIN',
  'FACILITY USER': 'FACILITY USER'
});

export const PERMISSIONS = Object.freeze({
  VIEW_LIVE_VIDEO: 'VIEW_LIVE_VIDEO',

  VIEW_DEVICES: 'VIEW_DEVICES',
  ADOPT_DEVICE: 'ADOPT_DEVICE',
  UPDATE_DEVICE_FIRMWARE: 'UPDATE_DEVICE_FIRMWARE',
  REBOOT_DEVICE: 'REBOOT_DEVICE',
  UPDATE_DEVICE_NETWORK_CONFIG: 'UPDATE_DEVICE_NETWORK_CONFIG',
  DELETE_DEVICE: 'DELETE_DEVICE',
  VIEW_DEVICE_LOGS: 'VIEW_DEVICE_LOGS',
  UPDATE_DEVICE_CONFIG: 'UPDATE_DEVICE_CONFIG',
  UPDATE_DEVICE_DETAILS: 'UPDATE_DEVICE_DETAILS',
  UPDATE_DEVICE_SETTINGS: 'UPDATE_DEVICE_SETTINGS',

  VIEW_EVAC_PLANS: 'VIEW_EVAC_PLANS',
  CREATE_EVAC_PLAN: 'CREATE_EVAC_PLAN',
  DELETE_EVAC_PLAN: 'DELETE_EVAC_PLAN',
  UPDATE_EVAC_PLAN: 'UPDATE_EVAC_PLAN',
  MANAGE_EVAC_PLAN: 'MANAGE_EVAC_PLAN',
  CREATE_FLOOR_PLAN: 'CREATE_FLOOR_PLAN',

  VIEW_ALERTS: 'VIEW_ALERTS',
  VIEW_REPLAY: 'VIEW_REPLAY',
  INITIATE_ALERT_EVAC: 'INITIATE_EVAC',
  RESOLVE_ALERT: 'RESOLVE_ALERT',

  UPDATE_FACILITY_DETAILS: 'UPDATE_FACILITY_DETAILS',
  VIEW_SETTINGS_CONTACTS: 'VIEW_SETTINGS_CONTACTS',
  CREATE_SETTINGS_CONTACTS: 'CREATE_SETTINGS_CONTACTS',
  UPDATE_SETTINGS_CONTACTS: 'UPDATE_SETTINGS_CONTACTS',
  DELETE_SETTINGS_CONTACTS: 'DELETE_SETTINGS_CONTACTS',
  VIEW_SETTINGS_DEVICES: 'VIEW_SETTINGS_DEVICES',
  CREATE_SETTINGS_DEVICES: 'CREATE_SETTINGS_DEVICES',
  UPDATE_SETTINGS_DEVICES: 'UPDATE_SETTINGS_DEVICES',
  VIEW_SETTINGS_ALERTS: 'VIEW_SETTINGS_ALERTS',
  UPDATE_SETTINGS_ALERTS: 'UPDATE_SETTINGS_ALERTS',
  VIEW_SETTINGS_NOTIFICATIONS: 'VIEW_SETTINGS_NOTIFICATIONS',
  UPDATE_SETTINGS_NOTIFICATIONS: 'UPDATE_SETTINGS_NOTIFICATIONS',
  VIEW_USERS: 'VIEW_USERS',
  CREATE_USER: 'CREATE USER',
  UPDATE_USER: 'UPDATE USER',
  DELETE_USER: 'DELETE USER',
  VIEW_NOTES: 'VIEW_NOTES',
  CREATE_NOTE: 'CREATE_NOTE',
  UPDATE_NOTE: 'UPDATE_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  VIEW_FIRMWARES: 'VIEW_FIRMWARES',
  CREATE_FIRMWARE: 'CREATE_FIRMWARE',
  UPDATE_FIRMWARE: 'UPDATE_FIRMWARE',
  DELETE_FIRMWARE: 'DELETE_FIRMWARE',
  DOWNLOAD_FIRMWARE: 'DOWNLOAD_FIRMWARE',
  MANAGE_ADMINISTRATION: 'MANAGE_ADMINISTRATION',
  MANAGE_ENVIRONMENT: 'MANAGE_ENVIRONMENT',
  MANAGE_MIGRATIONS: 'MANAGE_MIGRATIONS',
  VIEW_PERMISSIONS: 'VIEW_PERMISSIONS',
  VIEW_LIVE_VIEW: 'VIEW_LIVE_VIEW',
  MANAGE_CONTROLLER: 'MANAGE_CONTROLLER',
});

export const ALERT_TYPES = Object.freeze({
  ACTIVE_SHOOTER: {
    id: 'activeShooter',
    name: 'Active Shooter'
  },
  THREAT_SCREAM: {
    id: 'threatScream',
    name: 'Threat Scream'
  },
  HOTWORD: {
    id: 'hotword',
    name: 'Hotword'
  },
  FIRE: {
    id: 'fire',
    name: 'Fire'
  },
  WHISTLE: {
    id: 'whistle',
    name: 'Whistle'
  }
});
